import {View, StyleSheet} from 'react-native';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import React from 'react';
import Text from '@/components/basic/text';
import theme from '@/style';
import globalStore from '@/services/global.state';
import {debounce} from '@/utils';

const FILTER_MENU = [
  {
    name: 'Kerala',
  },
  {
    name: '3 Digit',
  },
  {
    name: 'Color',
  },
  {
    name: 'Dice',
  },
  {
    name: 'Satta Matka',
  },
  {
    name: 'Scratch off',
  },
  {
    name: 'Casino',
  },
  {
    name: 'Live',
  },
];

const K3_MENU = [
  {
    name: 'Color',
  },
  {
    name: 'Dice',
  },
  {
    name: 'Scratch off',
  },
  {
    name: 'Casino',
  },
  {
    name: 'Live',
  },
];

const FILTER_ID = [5, 6];

const BetsFilter = (props: {
  value?: string;
  onChange?: (v: string) => void;
}) => {
  const {value = 'Kerala', onChange = () => {}} = props;

  const changeName = debounce((name: string) => {
    onChange(name);
  });

  return (
    <View style={[theme.padding.lrl, theme.padding.tbs]}>
      <View style={styles.container}>
        {(FILTER_ID.includes(globalStore.packageId)
          ? K3_MENU
          : FILTER_MENU
        ).map((item, index) => (
          <NativeTouchableOpacity
            style={[styles.item, value === item.name && styles.itemSelected]}
            key={index}
            onPress={() => changeName(item.name)}>
            <Text
              color={
                value === item.name
                  ? theme.basicColor.primary
                  : theme.basicColor.dark
              }>
              {item.name}
            </Text>
          </NativeTouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    marginTop: 8,
    marginRight: 8,
    borderWidth: 1,
    backgroundColor: '#F6F7FB',
    borderColor: 'transparent',
    borderRadius: 30,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  itemSelected: {
    borderColor: theme.basicColor.primary,
    ...theme.background.white,
  },
});

export default BetsFilter;
