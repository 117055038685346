// import ShareButton from '@/components/business/bets/share-btn';
import React from 'react';
import {View, StyleSheet} from 'react-native';
import Text from '@components/basic/text';
import theme from '@/style';
import {useTranslation} from 'react-i18next';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';

const TableTitle = ({
  title = '',
}: // hasShare = false,
// onShare = () => {},
{
  title?: string;
  hasShare?: boolean;
  onShare?: () => void;
}) => {
  const {i18n} = useTranslation();
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={['#fff', 'rgba(255, 255, 255, 0.00)']}>
      <View
        style={[
          theme.flex.row,
          theme.padding.l,
          theme.flex.centerByCol,
          theme.flex.between,
          styles.container,
        ]}>
        <Text size="medium" blod>
          {title || i18n.t('me.bottom.myBets')}
        </Text>
        {/* {hasShare && <ShareButton onShare={onShare} />} */}
      </View>
    </LinearGradient>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
});

export default TableTitle;
